import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import SockJS from 'sockjs-client';
import store from './store';
import './index.css';
import App from './App';
import { unregister } from './serviceWorker';

window.SockJS = SockJS;

ReactDOM.render(
  <Provider store={store()}>
    <App />
  </Provider>,
  document.getElementById('root'),
);
unregister();
