import {
  SET_DARK_MODE,
} from 'constants/app';

export default (state = {
  darkMode: window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches,
}, action) => {
  switch (action.type) {
    case SET_DARK_MODE:
      return { ...state, darkMode: action.token };
    default:
      return state;
  }
};
