import React, {useState, useEffect} from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
//import Box from '@material-ui/core/Box';

import { formatAMPM } from 'helpers/date';

// styles
import styles from './styles';
import GreyTextTypography from 'hooks/GreyTextTypography';

const useStyles = makeStyles(styles);

export default function Clock() {
  const [currentDate, setCurrentDate] = useState(formatAMPM());
  const classes = useStyles(styles);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrentDate(formatAMPM());
    }, 1000);
    return () => clearTimeout(timer);
  });

  return (
    <div className={classes.container}>
      <Typography variant="h2" component="span" className={classes.bolderClock}>
        {currentDate.hours}:{currentDate.minutes}:{currentDate.seconds}
      </Typography>
      <GreyTextTypography component="span" className={classes.bolderClock}>{currentDate.ampm}</GreyTextTypography>
    </div>
  );
}

