import React from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Fab from '@material-ui/core/Fab';

// styles
import styles from './styles';
import { Box } from '@material-ui/core';


const useStyles = makeStyles(styles);

export default function PadNumber({numberLabel, textLabel, disabled, onClick}) {
  const [ textVariant ] = React.useState(numberLabel ? 'caption' : 'subtitle1');
  const classes = useStyles(styles);

  return (
   <Fab aria-label={numberLabel} disabled={disabled} onClick={() => onClick && onClick(numberLabel)}>
    <Box>
      {
        numberLabel && <Typography variant="h4" className={classes.numberLabel}>{numberLabel}</Typography>
      }&nbsp;
      {
        textLabel && <Typography variant={textVariant} className={classes.textLabel} align="center">{textLabel}</Typography>
      }&nbsp;
    </Box>
  </Fab>
  );
}

