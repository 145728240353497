export default theme => ({
  container: {
    padding: 5,
  },
  punches: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
});
