import React, { useEffect } from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';
import TextField from '@material-ui/core/TextField';

import useDebounce from '../../useDebounce';

const useStyles = makeStyles(styles);

export default function PinInput({text}) {
  const [ label, setLabel ] = React.useState(text);

  const classes = useStyles(styles);

  const timeout = useDebounce(label, 1000);

  useEffect(() => {
    if (timeout) {
      setLabel("*");
    }
  }, [timeout]);

  useEffect(() => {
    setLabel(text);
  }, [text]);

  return (
    <TextField variant="outlined" inputProps={{className: classes.pinInput, readOnly: true}} value={label || ''} />
  );
}

