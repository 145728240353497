import { combineReducers } from "redux"; //eslint-disable-line
import app from "./app";
import user from "./user";
import clock from "./clock";

export default combineReducers({
  user,
  app,
  clock
});
