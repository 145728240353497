export function handleLoading({state, action}) {
  return {
    ...state,
    [action]: {
      ...state[action],
      loading: true
    }
  }
}

export function handleSuccess({state, action, payload}) {
  const message = payload && (payload.message || '');
  return {
    ...state,
    [action]: {
      ...state[action],
      loading: false,
      success: true,
      data: payload,
      message
    }
  };
}

export function handleError({state, action, payload}) {
  const message = payload && (payload.message || payload.description);
  return {
    ...state,
    [action]: {
      ...state[action],
      loading: false,
      success: false,
      message: message || "There was an error, please try again" 
    }
  };
}
