import React, {useEffect} from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';

// styles
import styles from './styles';
import PadNumber from './PadNumber';
import Grid from '@material-ui/core/Grid';
import PinInput from './PinInput';

const useStyles = makeStyles(styles);

export default function DialPad({pin, onClick, onDelete, onClear}) {
  const [ clearDisabled, setClearDisabled ] = React.useState(true);
  const classes = useStyles(styles);

  useEffect(() => {
    setClearDisabled(!Boolean(pin.length));
  }, [pin])

  return (
    <Grid className={classes.container} container spacing={2}>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={3}>
          <PinInput text={pin[0]}/>
        </Grid>
        <Grid item xs={3}>
          <PinInput text={pin[1]}/>
        </Grid>
        <Grid item xs={3}>
          <PinInput text={pin[2]}/>
        </Grid>
        <Grid item xs={3}>
          <PinInput text={pin[3]}/>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <PadNumber numberLabel="1" onClick={onClick}/>
        </Grid>
        <Grid item xs={4}>
          <PadNumber numberLabel="2" onClick={onClick} textLabel="A B C" />
        </Grid>
        <Grid item xs={4}>
          <PadNumber numberLabel="3" onClick={onClick} textLabel="D E F" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <PadNumber numberLabel="4" onClick={onClick} textLabel="G H I" />
        </Grid>
        <Grid item xs={4}>
          <PadNumber numberLabel="5" onClick={onClick} textLabel="J K L" />
        </Grid>
        <Grid item xs={4}>
          <PadNumber numberLabel="6" onClick={onClick} textLabel="M N O" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <PadNumber numberLabel="7" onClick={onClick} textLabel="P Q R S" />
        </Grid>
        <Grid item xs={4}>
          <PadNumber numberLabel="8" onClick={onClick} textLabel="T U V" />
        </Grid>
        <Grid item xs={4}> 
          <PadNumber numberLabel="9" onClick={onClick} textLabel="W X Y Z" />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={4}>
          <PadNumber textLabel="X" onClick={onClear} disabled={clearDisabled} />
        </Grid>
        <Grid item xs={4}>
          <PadNumber numberLabel="0" onClick={onClick} textLabel="+" />
        </Grid>
        <Grid item xs={4}>
          <PadNumber textLabel="Del" onClick={onDelete} />
        </Grid>
      </Grid>
    </Grid>
  );
}

