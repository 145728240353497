import { grey } from '@material-ui/core/colors';
import { withStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const GreyTextTypography = withStyles({
  root: {
    color: grey[500],
    lineHeight: 1
  }
})(Typography);

export default GreyTextTypography;
