// material-ui
import { withStyles } from "@material-ui/styles";
// components
import App from "components/App";
import React, { Component } from "react";
import { connect } from "react-redux";
// styles
import styles from "./styles";

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({});

class Root extends Component {
  static propTypes = {};

  render() {
    return <App />;
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Root));
