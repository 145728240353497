import {
  META_ONLY,
} from 'constants/methods';

import Fetcher from 'helpers/network/network';

const fetcher = new Fetcher();

export async function networkAction(dispatch, action, apiCall, args) {
  let call;
  if (args[0] === META_ONLY) {
    call = apiCall('');
    return call;
  }

  call = apiCall(...args);

  dispatch({ type: `${action}_LOADING` });
  const resp = await fetcher[call.method]({ url: call.url, body: call.body });
  let json;
  try {
    json = await resp.json();
  } catch (e) {
    console.log(e);
  }

  // if (resp.status === 401) {
  //   debugger;
  // }

  if ((resp.status !== 200) && (resp.status !== 204)) {
    const ac = {
      type: `${action}_ERROR`,
      payload: json,
      success: false,
    };
    dispatch(ac);
    return ac;
  }

  const ac = {
    type: `${action}_SUCCESS`,
    payload: json,
    success: true,
  };
  dispatch(ac);
  return ac;
}
