export default theme => ({
  container: {
    padding: 5,
    textAlign: 'center',
    marginBottom: 15
  },
  bolderClock: {
    fontWeight: 700
  }
});
