export function diff(start, end) {
  var diff = end.getTime() - start.getTime();
  var hours = Math.floor(diff / 1000 / 60 / 60);
  diff -= hours * 1000 * 60 * 60;
  var minutes = Math.floor(diff / 1000 / 60);

  // If using time pickers with 24 hours format, add the below line get exact hours
  if (hours < 0)
    hours = hours + 24;

  return {hours, minutes};
}

export function extractWeedDay(date) {
  if (!date) {
    date = new Date();
  }
  return date.toDateString().slice(0, 3);
}

export function formatAMPM(date) {
  if (!date) {
    date = new Date();
  }
  let hours = date.getHours();
  const ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12;
  return {hours, minutes: zeroPadStart(date.getMinutes()), seconds: zeroPadStart(date.getSeconds()), ampm};
}

export function zeroPadStart(str, n = 2) {
  return str.toString().padStart(n, '0');
}
