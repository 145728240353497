import { useIdleTimer } from 'react-idle-timer'

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Grid from "@material-ui/core/Grid";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from '@material-ui/icons/Close';

import ClockInOutManagement from "hooks/ClockInOutManagement";
import DialPad from "hooks/DialPad";

import React, { useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { GET_STATUS, CREATE_TIME_PUNCH } from 'constants/clock';

import * as Actions from "actions";

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function App() {
  const classes = useStyles(styles);

  const [ pin, setPin ] = React.useState('');
  const [alert, setAlert] = React.useState({open: false, message: ''});

  const dispatch = useDispatch();

  const getStatusReq = useSelector(state => state.clock)[GET_STATUS];
  const createTimePunchReq = useSelector(state => state.clock)[CREATE_TIME_PUNCH];

  useIdleTimer({timeout: 6000, onIdle: () => pin && handleClose()});

  useEffect(() => {
    if (!alert.open) {
      const message = getStatusReq.message || createTimePunchReq.message;

      if (message) {
        setAlert({open: true, message});
        dispatch(Actions.clearMessage());
      }
    }
  }, [dispatch, getStatusReq, createTimePunchReq, alert]);


  const handleClick = (number) => {
    if (pin.length < 4) {
      setPin((pin) => {
        const newPin = pin + number;
        if (newPin.length === 4) {
          dispatch(Actions.getStatus(newPin));
        }
        return newPin;
      });
    }
  }

  const handleDelete = () => {
    if (pin) {
      setPin((pin) => pin.slice(0, -1));
    }
  }

  const handleClear = () => {
    setPin('');
  }

  const handleClose = () => {
    dispatch(Actions.clearClock());
    handleClear();
  }

  const handleCloseAlert = () => {
    setAlert({...alert, open: false});
  };
  
  return <>
    { getStatusReq.success && 
      <IconButton aria-label="close" onClick={handleClose} className={classes.button}>
        <CloseIcon />
      </IconButton>
    }

    <Backdrop className={classes.backdrop} open={getStatusReq.loading || createTimePunchReq.loading}>
      <CircularProgress color="inherit" />
    </Backdrop>

    <Snackbar open={alert.open} autoHideDuration={6000} onClose={handleCloseAlert} onClick={handleCloseAlert} anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}>
      <Alert onClose={handleCloseAlert} severity="warning">
        {alert.message}
      </Alert>
    </Snackbar>

    <Grid container>
      <Grid container item sm={12} md={6} alignItems="center" className={classes.px}>
        <ClockInOutManagement pin={pin}/>
      </Grid>
      <Grid container item sm={12} md={6} alignItems="center" justify="center">
        <DialPad pin={pin} onClick={handleClick} onDelete={handleDelete} onClear={handleClear}/>
      </Grid>
    </Grid>
  </>;
}
