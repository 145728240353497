import * as colors from '@material-ui/core/colors';

export default theme => ({
  root: {
    color: 'white',
    backgroundColor: props => colors[props.color][500],
    '&:hover': {
      backgroundColor: props => colors[props.color][700],
    },
  },
});
