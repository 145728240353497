import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import * as Actions from "actions";
import { GET_STATUS } from "constants/clock";
import ClockHistory from "hooks/ClockHistory/ClockHistory";
import ColoredButton from "hooks/ColoredButton";
import GreyTextTypography from "hooks/GreyTextTypography";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Clock from "./Clock/Clock";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ClockInOutManagement({ pin }) {
  // const [ state, setState ] = useState(initialValue);
  const classes = useStyles(styles);

  const dispatch = useDispatch();

  const getStatusReq = useSelector((state) => state.clock)[GET_STATUS];

  const handlePunch = () => {
    dispatch(Actions.createTimePunch(pin));
  };

  function getGreetings() {
    const curHr = new Date().getHours();
    let greeting = "Good evening";
    if (curHr < 12) {
      greeting = "Good morning";
    } else if (curHr < 18) {
      greeting = "Good afternoon";
    }
    return `${greeting} ${getStatusReq.data.firstName}!`;
  }

  return (
    <Grid container direction="column">
      <Grid item>
        <Clock />
      </Grid>

      {!getStatusReq.success && (
        <Grid item>
          <GreyTextTypography
            variant="h3"
            align="center"
            className={classes.welcome}
          >
            Welcome!
          </GreyTextTypography>
          <GreyTextTypography variant="body1" align="center">
            Enter your pin to get started
          </GreyTextTypography>
        </Grid>
      )}

      {getStatusReq.success && (
        <Grid item>
          <Box
            lineHeight="normal"
            fontSize="h6.fontSize"
            fontWeight="fontWeightBold"
          >
            {getGreetings()}
          </Box>
          <GreyTextTypography>
            These are your last {getStatusReq.data.history.length} clock
            records:
          </GreyTextTypography>
          <Box mt={2}>
            {getStatusReq.data.history.map((h) => (
              <ClockHistory key={h.id} historyDay={h} />
            ))}
          </Box>
        </Grid>
      )}

      {getStatusReq.success && (
        <Grid item>
          {getStatusReq.data.status === "out" && (
            <ColoredButton
              color="green"
              variant="contained"
              onClick={handlePunch}
            >
              Clock in
            </ColoredButton>
          )}
          {getStatusReq.data.status === "in" && (
            <ColoredButton
              color="red"
              variant="contained"
              onClick={handlePunch}
            >
              Clock out
            </ColoredButton>
          )}
        </Grid>
      )}
    </Grid>
  );
}
