import { REACT_APP_API_PREFIX } from "config/env";
import { GET, POST } from "constants/methods";

const service = "clock";

export function getStatus(pin) {
  const url = `${REACT_APP_API_PREFIX}/status/${pin}`;
  return {
    service,
    method: GET,
    url,
  };
}

export function createTimePunch(pin) {
  const url = `${REACT_APP_API_PREFIX}/punches`;
  return {
    service,
    method: POST,
    url,
    body: {pin, clockTime: new Date().toISOString()}
  };
}
