import {
  REACT_APP_API,
} from 'config';

export default class Fetcher {
  get(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      method: 'GET',
    });
  }

  post(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      method: 'POST',
    });
  }

  data(request) {
    console.log(request);
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: request.body,
      credentials: 'include',
      // headers: {
      //   'content-type': 'multipart/form-data; charset=utf-8; boundary=__X_PAW_BOUNDARY__',
      // },
      method: 'POST',
    });
  }

  delete(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      method: 'DELETE',
    });
  }

  put(request) {
    return fetch(`${REACT_APP_API}${request.url}`, {
      body: JSON.stringify(request.body),
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
      },
      method: 'PUT',
    });
  }
}
