import { handleError, handleLoading, handleSuccess } from "helpers";
import { GET_STATUS, CREATE_TIME_PUNCH, REQUEST_INITIAL_STATE, CLEAR_CLOCK, CLEAR_MESSAGE } from "../constants";

const initialState = {
  [GET_STATUS]: REQUEST_INITIAL_STATE,
  [CREATE_TIME_PUNCH]: REQUEST_INITIAL_STATE
}

export default (state = initialState, {type, payload}) => {
  switch (type) {
    case `${GET_STATUS}_LOADING`:
      return handleLoading({state, action: GET_STATUS});
    case `${GET_STATUS}_SUCCESS`:
      return handleSuccess({state, action: GET_STATUS, payload});
    case `${GET_STATUS}_ERROR`:
      return handleError({state, action: GET_STATUS, payload});
    case `${CREATE_TIME_PUNCH}_LOADING`:
      return handleLoading({state, action: CREATE_TIME_PUNCH});
    case `${CREATE_TIME_PUNCH}_SUCCESS`:
      return {
        ...state,
        ...handleSuccess({state, action: GET_STATUS, payload}),
        [CREATE_TIME_PUNCH]: REQUEST_INITIAL_STATE
      };
    case `${CREATE_TIME_PUNCH}_ERROR`:
      return handleError({state, action: CREATE_TIME_PUNCH, payload});
    case CLEAR_CLOCK:
      return initialState;
    case CLEAR_MESSAGE:
      return {
        [GET_STATUS]: {...state[GET_STATUS], message: ''},
        [CREATE_TIME_PUNCH]: {...state[CREATE_TIME_PUNCH], message: ''}
      };
    default:
      return state;
  }
};