import React from 'react';
// import PropTypes from 'prop-types';

// material-ui
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import EmptySpot from 'hooks/EmptySpot';

// styles
import styles from './styles';

const useStyles = makeStyles(styles);

export default function ClockHistoryDiffValues(props) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(props);

  return <div className={classes.progress}>
    {
      props.diff1 ? <Typography>{props.diff1}</Typography> : <EmptySpot />
    }
    {
      props.diff2 ? <Typography>{props.diff2}</Typography> : <EmptySpot />
    }
    {
      props.diff3 ? <Typography>{props.diff3}</Typography> : <EmptySpot />
    }
  </div>;
}

