import Box from "@material-ui/core/Box";
// import PropTypes from 'prop-types';
// material-ui
import { makeStyles } from "@material-ui/core/styles";
import LocalDiningIcon from "@material-ui/icons/LocalDining";
import WorkIcon from "@material-ui/icons/Work";
import { diff, extractWeedDay, formatAMPM, zeroPadStart } from "helpers/date";
import ClockHistoryDiffValues from "hooks/ClockHistoryDiffValues";
import EmptySpot from "hooks/EmptySpot";
import React from "react";
// styles
import styles from "./styles";

const useStyles = makeStyles(styles);

export default function ClockHistory({ historyDay }) {
  // const [ state, setState ] = React.useState(initialValue);
  const classes = useStyles(styles);

  const printPunches = () => {
    const currentDate = new Date(historyDay.date);
    const date = zeroPadStart(currentDate.getDate());
    const month = zeroPadStart(currentDate.getMonth() + 1);
    const punches = historyDay.punches
      .map((p) => ({
        ...p,
        startTime: new Date(p.startTime),
        formatted: formatAMPM(new Date(p.startTime)),
      }))
      .sort((a, b) => a.startTime.getTime() - b.startTime.getTime());

    const data = {
      in1: "",
      out1: "",
      in2: "",
      out2: "",
      diff1: "",
      diff2: "",
      diff3: "",
    };

    if (punches[0]) {
      data.in1 = `${punches[0].formatted.hours}:${punches[0].formatted.minutes}`;
    }

    if (punches[1]) {
      data.out1 = `${punches[1].formatted.hours}:${punches[1].formatted.minutes}`;
      const d = diff(punches[0].startTime, punches[1].startTime);
      data.diff1 = diffValue(d);
    }

    if (punches[2]) {
      data.in2 = `${punches[2].formatted.hours}:${punches[2].formatted.minutes}`;
      const d = diff(punches[1].startTime, punches[2].startTime);
      data.diff2 = diffValue(d);
    }

    if (punches[3]) {
      data.out2 = `${punches[3].formatted.hours}:${punches[3].formatted.minutes}`;
      const d = diff(punches[2].startTime, punches[3].startTime);
      data.diff3 = diffValue(d);
    }

    return (
      <>
        <Box lineHeight="normal" fontSize={15} fontWeight="fontWeightBold">
          {extractWeedDay(currentDate)} {month}/{date}
        </Box>
        <Box px={1}>
          <div className={classes.punches}>
            {data.in1 ? (
              <Box fontSize={13} fontWeight="fontWeightBold">
                {data.in1}
              </Box>
            ) : (
              <EmptySpot />
            )}
            <WorkIcon />
            {data.out1 ? (
              <Box fontSize={13} fontWeight="fontWeightBold">
                {data.out1}
              </Box>
            ) : (
              <EmptySpot />
            )}
            <LocalDiningIcon />
            {data.in2 ? (
              <Box fontSize={13} fontWeight="fontWeightBold">
                {data.in2}
              </Box>
            ) : (
              <EmptySpot />
            )}
            <WorkIcon />
            {data.out2 ? (
              <Box fontSize={13} fontWeight="fontWeightBold">
                {data.out2}
              </Box>
            ) : (
              <EmptySpot />
            )}
          </div>
          <ClockHistoryDiffValues
            diff1={data.diff1}
            diff2={data.diff2}
            diff3={data.diff3}
          />
        </Box>
      </>
    );
  };

  function diffValue(diff, redLabeled) {
    const hasHours = Boolean(diff.hours);
    const diffVal = `${hasHours ? diff.hours + "h" : ""}${diff.minutes}${
      hasHours ? "" : "mn"
    }`;
    return (
      <strong style={{ fontSize: 12, color: redLabeled ? "red" : "inherit" }}>
        {diffVal}
      </strong>
    );
  }

  return printPunches();
}
