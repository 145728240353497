import {blue, indigo, grey} from '@material-ui/core/colors';

export default theme => ({
  progress: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'flex-end',
    height: 23,
    width: '100%',
    margin: '0 0 1.75rem 0',
    color: 'white',
    fontSize: 20,
    borderRadius: 3,
    background: props => `linear-gradient(to right, ${props.diff1 ? blue[500] : grey[300]} 40%, ${props.diff2 ? indigo[900] : indigo[300]} 40%, ${props.diff2 ? indigo[900] : indigo[300]} 60%, ${props.diff3 ? blue[500] : grey[300]} 0)`
  }
});
