import { networkAction } from "helpers/network/networkAction";

import {
  GET_STATUS, CLEAR_CLOCK, CLEAR_MESSAGE, CREATE_TIME_PUNCH
} from "constants/clock";

import * as Api from "api";

export const getStatus = (pin) => async (dispatch) => networkAction(dispatch, GET_STATUS, Api.getStatus, [pin]);

export const createTimePunch = (pin) => async (dispatch) => networkAction(dispatch, CREATE_TIME_PUNCH, Api.createTimePunch, [parseInt(pin, 10)]);

export const clearClock = () => async (dispatch) => dispatch({type: CLEAR_CLOCK});

export const clearMessage = () => async (dispatch) => dispatch({type: CLEAR_MESSAGE});
