export default (theme) => ({
  px: {
    padding: '0 20px'
  },
  button: {
    position: 'absolute',
    right: 0
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  }
});